export const dict = {
	fi: {
		"Password *": "Salasana",
		Password: "Salasana",
		"Enter your password": "Anna salasanasi",
		"Sign In": "Kirjaudu",
		"Forgot your password?": "Unohditko salasanasi?",
		"Reset password": "Palauta salasana",
		"Reset your password": "Palauta salasanasi",
		"Sign in to your account": "Kirjaudu palveluun",
		"Back to Sign In": "Takaisin kirjautumiseen",
		"Send Code": "Palauta",
		"No account?": "Uusi käyttäjä?",
		"Have an account?": "Oletko jo rekisteröitynyt?",
		"Create account": "Luo tili",
		"Email Address *": "Sähköpostiosoite *",
		"Enter your email address": "Anna sähköpostiosoitteesi",
		"Create Account": "Luo tili",
		"Create a new account": "Luo uusi tili",
		"Incorrect username or password.": "Väärä sähköposti tai salasana.",
		"Username cannot be empty": "Sähköposti ei voi olla tyhjä",
		"Sign in": "Kirjaudu",
		"Confirm Sign up": "Vahvista rekisteröityminen",
		"Confirmation Code": "Varmistuskoodi",
		"Lost your code?": "Koodi hävinnyt?",
		"Resend Code": "Lähetä uudestaan",
		"An account with the given email already exists.":
			"Sähköposti on jo käytössä.",
		Confirm: "Vahvista",
		"Verification code": "Varmistuskoodi",
		"Enter code": "Anna koodi",
		"New password": "Uusi salasana",
		"Enter your new password": "Anna uusi salasana",
		Submit: "Lähetä",
		"Username/client id combination not found.": "Sähköpostia ei löydy.",
		"Password cannot be empty": "Salasana ei voi olla tyhjä",
		"Invalid verification code provided, please try again.":
			"Virheellinen varmistuskoodi",
		"User does not exist.": "Väärä sähköposti tai salasana.",
		"Custom auth lambda trigger is not configured for the user pool.":
			"Väärä sähköposti tai salasana.",
	},
};
