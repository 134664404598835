// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Moment = {
  "MORNING": "MORNING",
  "EVENING": "EVENING"
};

const { Tracking, Measurement, Sample } = initSchema(schema);

export {
  Tracking,
  Measurement,
  Moment,
  Sample
};