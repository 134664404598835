import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DataStore } from "@aws-amplify/datastore";
import { Tracking } from "../models";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toAwsDate } from "../functions";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";

export default function TrackingForm({ reFetch }: { reFetch: () => void }) {
	const currentDate = new Date();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const [open, setOpen] = useState<boolean>(false);
	const [name, setName] = useState<string>("");
	const [startDate, setStartDate] = useState<Date | null>(currentDate);
	const [endDate, setEndDate] = useState<Date | null>(
		new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000)
	);
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	};

	const saveTracking = async () => {
		if (!name || !startDate || !endDate)
			return setError("Kaikki kentät ovat pakollisia!");
		setLoading(true);
		try {
			await DataStore.save(
				new Tracking({
					name,
					startDate: toAwsDate(startDate),
					endDate: toAwsDate(endDate),
				})
			);
			setLoading(false);
			reFetch();
			setOpen(false);
			setName("");
		} catch (e) {
			setLoading(false);
			setError("Jokin meni pieleen!");
			console.error(e);
		}
	};

	return (
		<div>
			<IconButton edge="start" color="inherit" onClick={handleClickOpen}>
				<AddCircleIcon fontSize="large" />
			</IconButton>
			<Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
				<DialogTitle>{"Aloita uusi seuranta"}</DialogTitle>
				<DialogContent>
					<Grid container direction="column">
						<Grid item>
							<TextField
								label="Nimi"
								variant="outlined"
								value={name}
								fullWidth
								style={{ maxWidth: 300 }}
								required
								onChange={handleNameChange}
							/>
						</Grid>
						<Grid item>
							<KeyboardDatePicker
								margin="normal"
								label="Aloituspäivämäärä"
								format="dd/MM/yyyy"
								value={startDate}
								maxDate={endDate}
								onChange={setStartDate}
								inputVariant="outlined"
							/>
						</Grid>
						<Grid item>
							<KeyboardDatePicker
								margin="normal"
								label="Lopetuspäivämäärä"
								format="dd/MM/yyyy"
								minDate={startDate}
								value={endDate}
								onChange={setEndDate}
								inputVariant="outlined"
							/>
						</Grid>
						{error && <Typography color="error">{error}</Typography>}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Peruuta
					</Button>
					<Button onClick={saveTracking} color="primary">
						{loading ? <CircularProgress /> : "Tallenna"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
