import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";
import CookieBot from "react-cookiebot";

TagManager.initialize({ gtmId: "GTM-TX6XHNZ" });

ReactDOM.render(
	<>
		<App />
		<CookieBot
			domainGroupId={"a63331dc-b260-4613-beb1-cfc4cd74d439"}
			language={"FI"}
		/>
	</>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
