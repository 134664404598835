import { Card, CardContent, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import React from "react";

export default function AverageMeasurementCard({
	title,
	systolic,
	diastolic,
}: {
	title: string;
	systolic: number;
	diastolic: number;
}) {
	return (
		<Card style={{ margin: 8 }}>
			<CardContent>
				<Typography gutterBottom={true} variant={"h6"}>
					{title}
				</Typography>
				<Grid container justify={"center"} alignItems={"center"}>
					<ArrowUpwardIcon style={{ color: "red" }} />
					<Typography variant={"body2"} style={{ marginRight: 8 }}>
						{systolic} mmHg
					</Typography>
					<ArrowDownwardIcon style={{ color: "blue" }} />
					<Typography variant={"body2"}>{diastolic} mmHg</Typography>
				</Grid>
			</CardContent>
		</Card>
	);
}
