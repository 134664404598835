import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Tracking } from "../models";
import TrackingForm from "./TrackingForm";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) => {
	const color = theme.palette.background.default;
	return createStyles({
		formControl: {
			margin: theme.spacing(1),
			minWidth: 160,
		},
		select: {
			"&:before": {
				borderColor: color,
			},
			"&:after": {
				borderColor: color,
			},
			color,
		},
		label: {
			color,
		},
		circular: {
			marginRight: 45,
		},
		icon: {
			fill: color,
		},
	});
});

export default function TrackingSelect({
	trackingList,
	fetchTrackingList,
	currentTracking,
	setCurrentTracking,
}: {
	trackingList: Tracking[] | undefined;
	fetchTrackingList: () => void;
	currentTracking: Tracking | null;
	setCurrentTracking: (id: Tracking) => void;
}) {
	const classes = useStyles();
	const handleTrackingChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		if (!event.target.value || !trackingList) return;
		const selectedTracking = trackingList.find(
			({ id }) => id === (event.target.value as string)
		);
		selectedTracking && setCurrentTracking(selectedTracking);
	};

	return (
		<Grid container justify="center" alignItems="center">
			<Grid item>
				{trackingList ? (
					<FormControl className={classes.formControl}>
						<InputLabel id="tracking-select-label" className={classes.label}>
							Valittu seuranta
						</InputLabel>
						<Select
							labelId="tracking-select-label"
							value={currentTracking?.id || ""}
							onChange={handleTrackingChange}
							label="Valittu seuranta"
							className={classes.select}
							inputProps={{
								classes: {
									icon: classes.icon,
								},
							}}
						>
							{trackingList.length && currentTracking ? (
								trackingList.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))
							) : (
								<MenuItem key={"key"} value={""}>
									Sinulla ei ole yhtään seurantaa
								</MenuItem>
							)}
						</Select>
					</FormControl>
				) : (
					<CircularProgress className={classes.circular} color="inherit" />
				)}
			</Grid>
			<Grid item>
				<TrackingForm reFetch={fetchTrackingList} />
			</Grid>
		</Grid>
	);
}
