import React, { useMemo } from "react";
import { Measurement } from "../models";
import {
	VictoryChart,
	VictoryAxis,
	VictoryLabel,
	VictoryLine,
	VictoryLegend,
	VictoryScatter,
	VictoryContainer,
} from "victory";

const Chart = ({
	measurements,
	setChartElement,
	title,
}: {
	measurements: Measurement[];
	setChartElement: (element: HTMLElement | null) => void;
	title: string;
}) => {
	const data = useMemo(
		() =>
			measurements.map((measurement) => {
				const parts = measurement.date.split("-");
				return {
					...measurement,
					date: `${measurement.moment === "MORNING" ? "Aamu" : "Ilta"}, ${
						parts[2]
					}.${parts[1]}.${parts[0]}`,
				};
			}),
		[measurements]
	);
	return (
		<VictoryChart
			containerComponent={
				<VictoryContainer
					containerRef={(element) => {
						setChartElement(element);
					}}
					style={{
						pointerEvents: "auto",
						userSelect: "auto",
						touchAction: "auto",
					}}
				/>
			}
		>
			<VictoryLabel text={title} x={225} y={10} textAnchor="middle" />
			<VictoryLegend
				x={140}
				y={20}
				orientation="horizontal"
				gutter={20}
				style={{ labels: { fontSize: 10 } }}
				data={[
					{ name: "Yläpaine", symbol: { fill: "red" } },
					{ name: "Alapaine", symbol: { fill: "blue" } },
				]}
			/>
			<VictoryAxis
				orientation="bottom"
				tickLabelComponent={<VictoryLabel dy={-7} dx={26} />}
				style={{ tickLabels: { angle: 40, fontSize: 8 } }}
			/>
			<VictoryAxis
				dependentAxis
				axisLabelComponent={<VictoryLabel dy={-10} />}
				domain={[50, 230]}
				label="mmHg"
				orientation="left"
				tickCount={20}
				style={{
					axis: { stroke: "none" },
					grid: { stroke: "grey", strokeDasharray: "4, 8" },
					tickLabels: { fontSize: 10 },
					axisLabel: { fontSize: 10 },
				}}
			/>
			<VictoryLine
				name="Alapaine"
				style={{
					data: { stroke: "blue" },
				}}
				data={data}
				x="date"
				y="averageSample.diastolic"
			/>
			<VictoryScatter
				style={{ data: { fill: "blue" } }}
				size={3}
				data={data}
				x="date"
				y="averageSample.diastolic"
			/>
			<VictoryLine
				name="Yläpaine"
				style={{
					data: { stroke: "red" },
				}}
				data={data}
				x="date"
				y="averageSample.systolic"
			/>
			<VictoryScatter
				style={{ data: { fill: "red" } }}
				size={3}
				data={data}
				x="date"
				y="averageSample.systolic"
			/>
		</VictoryChart>
	);
};
export default Chart;
