import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import LogoNega from "../Apteq_logo_nega.png";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			top: "auto",
			bottom: 0,
		},
	})
);

export default function Footer() {
	const classes = useStyles();

	return (
		<AppBar position="fixed" color="primary" className={classes.appBar}>
			<Toolbar>
				<Grid container alignItems="center" justify="center">
					<a
						href="https://apteq.fi/"
						target={"_blank"}
						rel="noreferrer"
						style={{ marginTop: 15 }}
					>
						<img src={LogoNega} alt={""} style={{ maxWidth: "100%" }} />
					</a>
				</Grid>
			</Toolbar>
		</AppBar>
	);
}
