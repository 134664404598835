import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { Measurement, Tracking } from "../models";
import { makeStyles } from "@material-ui/core/styles";
import { resolveAdditionalInfo } from "../functions";
import UpdateMeasurement from "./UpdateMeasurement";
import Grid from "@material-ui/core/Grid";

const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
});

export default function MeasurementRow({
	measurement,
	currentTracking,
	reFetch,
}: {
	measurement: Measurement;
	currentTracking: Tracking;
	reFetch: () => void;
}) {
	const {
		date,
		moment,
		averageSample,
		additionalInfo,
		comment,
		samples,
	} = measurement;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();
	const formatDate = (awsDate: string) => {
		const parts = awsDate.split("-");
		return `${parts[2]}.${parts[1]}.${parts[0]}`;
	};
	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{`${moment === "MORNING" ? "Aamu" : "Ilta"}, ${formatDate(date)}`}
				</TableCell>
				<TableCell>{averageSample.systolic}</TableCell>
				<TableCell>{averageSample.diastolic}</TableCell>
				<TableCell>{averageSample.heartRate}</TableCell>
				<TableCell>
					<Typography color="error" variant="body2">
						{resolveAdditionalInfo(additionalInfo)}
					</Typography>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							{comment && (
								<>
									<Typography variant="subtitle1" gutterBottom component="div">
										Huomiot:
									</Typography>
									<Box
										style={{ marginBottom: 16, padding: 10, width: "50%" }}
										bgcolor="action.disabledBackground"
									>
										{comment}
									</Box>
								</>
							)}
							<Typography variant="subtitle1" gutterBottom component="div">
								Näytteet:
							</Typography>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Yläpaine (mmHg)</TableCell>
										<TableCell>Alapaine (mmHg)</TableCell>
										<TableCell>Syke (bpm)</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{samples.map(({ systolic, diastolic, heartRate }, idx) => (
										<TableRow key={idx}>
											<TableCell component="th" scope="row">
												{systolic}
											</TableCell>
											<TableCell>{diastolic}</TableCell>
											<TableCell>{heartRate}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
						<Grid container justify={"flex-end"}>
							<UpdateMeasurement
								reFetch={reFetch}
								measurement={measurement}
								currentTracking={currentTracking}
							/>
						</Grid>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}
