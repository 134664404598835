import React, { useEffect, useMemo, useState } from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFDownloadLink,
	Image,
} from "@react-pdf/renderer";
import { Button } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Measurement } from "../models";
import { formatDate, resolveAdditionalInfo } from "../functions";
import Logo from "../Apteq_logo.png";
import { AverageMeasurements } from "../App";

const { svgAsPngUri } = require("save-svg-as-png");

const PdfDocument = ({
	trackingName,
	measurements,
	chart,
	averageMeasurements,
}: {
	trackingName: string;
	measurements: Measurement[];
	chart: HTMLElement | null;
	averageMeasurements: AverageMeasurements;
}) => {
	const [chartBuffer, setChartBuffer] = useState<Buffer | null>(null);
	useEffect(() => {
		(async () => {
			if (chart) {
				const svg = chart.firstChild;
				const dataUri = await svgAsPngUri(svg, { scale: 3 });
				const data = dataUri.split(",")[1];
				const buffer = await Buffer.from(data, "base64");
				setChartBuffer(buffer);
			}
		})();
	}, [chart]);
	return useMemo(
		() =>
			chartBuffer ? (
				<PDFDownloadLink
					document={
						<MyDocument
							measurements={measurements}
							chartBuffer={chartBuffer}
							averageMeasurements={averageMeasurements}
						/>
					}
					fileName={`Verenpainekortti_${trackingName.replaceAll(
						" ",
						"_"
					)}_seurantajakso.pdf`}
					style={{ textDecoration: "none", marginTop: 10, marginLeft: 5 }}
				>
					{({ blob, url, loading, error }) => (
						<Button
							disabled={loading}
							variant={"contained"}
							color={"secondary"}
						>
							<PictureAsPdfIcon />
						</Button>
					)}
				</PDFDownloadLink>
			) : (
				<div style={{ margin: 10 }}>
					<Button disabled={true} variant={"contained"} color={"secondary"}>
						<PictureAsPdfIcon />
					</Button>
				</div>
			),
		[measurements, chartBuffer, averageMeasurements, trackingName]
	);
};

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const FIRST_COL_WIDTH = 20;
const LAST_COL_WIDTH = 30;
const COL_WIDTH = (100 - FIRST_COL_WIDTH - LAST_COL_WIDTH) / 4;
const COL_WIDTH_AVERAGE = 100 / 3;

const styles = StyleSheet.create({
	body: {
		padding: 10,
	},
	table: {
		display: "table",
		width: "auto",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderRightWidth: 0,
		borderBottomWidth: 0,
	},
	tableRow: {
		flexDirection: "row",
	},
	firstAverageColHeader: {
		width: COL_WIDTH_AVERAGE + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderTopWidth: 0,
	},
	lastAverageColHeader: {
		width: COL_WIDTH_AVERAGE + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	averageColHeader: {
		width: COL_WIDTH_AVERAGE + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	firstColHeader: {
		width: FIRST_COL_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderTopWidth: 0,
	},
	lastColHeader: {
		width: LAST_COL_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	colHeader: {
		width: COL_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	firstAverageCol: {
		width: COL_WIDTH_AVERAGE + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderTopWidth: 0,
	},
	lastAverageCol: {
		width: COL_WIDTH_AVERAGE + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	averageCol: {
		width: COL_WIDTH_AVERAGE + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	firstCol: {
		width: FIRST_COL_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderTopWidth: 0,
	},
	lastCol: {
		width: LAST_COL_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	col: {
		width: COL_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableCellHeader: {
		margin: 5,
		fontSize: 12,
		fontWeight: 500,
	},
	tableCell: {
		padding: 3,
		fontSize: 10,
	},
});

const MyDocument = ({
	measurements,
	chartBuffer,
	averageMeasurements,
}: {
	measurements: Measurement[];
	chartBuffer: Buffer;
	averageMeasurements: AverageMeasurements;
}) => {
	const table = useMemo(
		() =>
			measurements.map(
				({ id, moment, date, averageSample, additionalInfo, comment }) => (
					<View style={styles.tableRow} key={id} wrap={false}>
						<View style={styles.firstCol}>
							<Text style={styles.tableCell}>{`${
								moment === "MORNING" ? "Aamu" : "Ilta"
							}, ${formatDate(date)}`}</Text>
						</View>
						<View style={styles.col}>
							<Text style={styles.tableCell}>{averageSample.systolic}</Text>
						</View>
						<View style={styles.col}>
							<Text style={styles.tableCell}>{averageSample.diastolic}</Text>
						</View>
						<View style={styles.col}>
							<Text style={styles.tableCell}>{averageSample.heartRate}</Text>
						</View>
						<View style={styles.col}>
							<Text style={styles.tableCell}>
								{resolveAdditionalInfo(additionalInfo)}
							</Text>
						</View>
						<View style={styles.lastCol}>
							<Text style={styles.tableCell}>{comment}</Text>
						</View>
					</View>
				)
			),
		[measurements]
	);
	return (
		chartBuffer && (
			<Document title={"Seurantakortti"}>
				<Page size={"A4"} style={styles.body}>
					<Image
						src={Logo}
						style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}
					/>
					{chartBuffer && (
						<Image
							src={{ data: chartBuffer, format: "png" }}
							style={{ marginTop: 10, marginBottom: 10 }}
						/>
					)}
					<Text style={{ margin: 8 }}>Mittaustuloksien keskiarvot</Text>
					<View style={styles.table}>
						<View style={styles.tableRow}>
							<View style={styles.firstAverageColHeader}>
								<Text style={styles.tableCellHeader}>Ajankohta</Text>
							</View>
							<View style={styles.averageColHeader}>
								<Text style={styles.tableCellHeader}>Yläpaine (mmHg)</Text>
							</View>
							<View style={styles.lastAverageColHeader}>
								<Text style={styles.tableCellHeader}>Alapaine (mmHg)</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.firstAverageCol}>
								<Text style={styles.tableCell}>Aamu</Text>
							</View>
							<View style={styles.averageCol}>
								<Text style={styles.tableCell}>
									{averageMeasurements.morning.systolic}
								</Text>
							</View>
							<View style={styles.lastAverageCol}>
								<Text style={styles.tableCell}>
									{averageMeasurements.morning.diastolic}
								</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.firstAverageCol}>
								<Text style={styles.tableCell}>Ilta</Text>
							</View>
							<View style={styles.averageCol}>
								<Text style={styles.tableCell}>
									{averageMeasurements.evening.systolic}
								</Text>
							</View>
							<View style={styles.lastAverageCol}>
								<Text style={styles.tableCell}>
									{averageMeasurements.evening.diastolic}
								</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.firstAverageCol}>
								<Text style={styles.tableCell}>Kaikki</Text>
							</View>
							<View style={styles.averageCol}>
								<Text style={styles.tableCell}>
									{averageMeasurements.all.systolic}
								</Text>
							</View>
							<View style={styles.lastAverageCol}>
								<Text style={styles.tableCell}>
									{averageMeasurements.all.diastolic}
								</Text>
							</View>
						</View>
					</View>
				</Page>
				<Page size={"A4"} style={styles.body}>
					<Text style={{ margin: 8 }}>Mittaustulokset</Text>
					<View style={styles.table}>
						<View style={styles.tableRow}>
							<View style={styles.firstColHeader}>
								<Text style={styles.tableCellHeader}>Ajankohta</Text>
							</View>
							<View style={styles.colHeader}>
								<Text style={styles.tableCellHeader}>Yläpaine (mmHg)</Text>
							</View>
							<View style={styles.colHeader}>
								<Text style={styles.tableCellHeader}>Alapaine (mmHg)</Text>
							</View>
							<View style={styles.colHeader}>
								<Text style={styles.tableCellHeader}>Syke (bpm)</Text>
							</View>
							<View style={styles.colHeader}>
								<Text style={styles.tableCellHeader}>Lisätieto</Text>
							</View>
							<View style={styles.lastColHeader}>
								<Text style={styles.tableCellHeader}>Huomiot</Text>
							</View>
						</View>
						{table}
					</View>
				</Page>
			</Document>
		)
	);
};

export default PdfDocument;
