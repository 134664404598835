import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TrackingSelect from "./TrackingSelect";
import { Auth, DataStore } from "aws-amplify";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Tracking } from "../models";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		item2: {
			order: 3,
			[theme.breakpoints.up("sm")]: {
				order: 2,
			},
			marginLeft: 10,
		},
		item3: {
			order: 2,
			[theme.breakpoints.up("sm")]: {
				order: 3,
			},
			marginLeft: 50,
		},
	})
);

export default function Bar({
	trackingList,
	fetchTrackingList,
	currentTracking,
	setCurrentTracking,
}: {
	trackingList: Tracking[] | undefined;
	fetchTrackingList: () => void;
	currentTracking: Tracking | null;
	setCurrentTracking: (id: Tracking) => void;
}) {
	const classes = useStyles();
	const [open, setOpen] = useState<boolean>(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const signOut = async () => {
		try {
			handleClose();
			await Auth.signOut();
			await DataStore.clear();
		} catch (error) {
			console.log("error signing out: ", error);
		}
	};
	return (
		<AppBar position="static">
			<Toolbar>
				<Grid container alignItems="center" justify="space-between">
					<Typography variant="h6">Seurantakortti</Typography>
					<Grid item className={classes.item2}>
						<TrackingSelect
							trackingList={trackingList}
							fetchTrackingList={fetchTrackingList}
							currentTracking={currentTracking}
							setCurrentTracking={setCurrentTracking}
						/>
					</Grid>
					<Grid item className={classes.item3}>
						<IconButton edge="start" color="inherit" onClick={handleClickOpen}>
							<ExitToAppIcon />
						</IconButton>
					</Grid>
				</Grid>
				<Dialog open={open} onClose={handleClose}>
					<DialogTitle>Kirjaudutaan ulos...</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Haluatko varmasti kirjautua ulos?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Ei
						</Button>
						<Button onClick={signOut} color="primary" autoFocus>
							Kyllä
						</Button>
					</DialogActions>
				</Dialog>
			</Toolbar>
		</AppBar>
	);
}
