import { Measurement, Sample } from "../models";

export const toAwsDate = (date: Date): string =>
	date.toISOString().split("T")[0];

export const formatDate = (awsDate: string) => {
	const parts = awsDate.split("-");
	return `${parts[2]}.${parts[1]}.${parts[0]}`;
};

export const resolveAdditionalInfo = (
	a: Measurement["additionalInfo"]
): string => {
	if (!a) return "";
	switch (a) {
		case "ARR":
			return "Rytmihäiriö";
		case "irregular heartbeat":
			return "Epäsäännöllinen syke";
		case "Afib":
			return "Eteisvärinä";
		case "PC":
			return "Lisälyönti";
		default:
			return "";
	}
};

export const calculateAverageSample = (samples: Sample[]): Sample => {
	const sampleSum = samples.reduce(
		(
			{ systolic, diastolic, heartRate },
			{ systolic: s, diastolic: d, heartRate: h }
		) => {
			return {
				systolic: systolic + s,
				diastolic: diastolic + d,
				heartRate: heartRate + h,
			};
		},
		{ systolic: 0, diastolic: 0, heartRate: 0 }
	);

	return new Sample({
		systolic: Math.round(sampleSum.systolic / samples.length),
		diastolic: Math.round(sampleSum.diastolic / samples.length),
		heartRate: Math.round(sampleSum.heartRate / samples.length),
	});
};
