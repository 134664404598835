import React from "react";
import { FormLabel } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Moment } from "../models";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";

export default function MomentPicker({
	moment,
	setMoment,
}: {
	moment: Moment;
	setMoment: (moment: Moment) => void;
}) {
	const handleMomentChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setMoment(event.target.value as Moment);

	return (
		<FormControl>
			<FormLabel component="legend">Ajankohta</FormLabel>
			<RadioGroup row value={moment} onChange={handleMomentChange}>
				<FormControlLabel
					value={Moment.MORNING}
					control={<Radio />}
					label="Aamu (06-09)"
				/>
				<FormControlLabel
					value={Moment.EVENING}
					control={<Radio />}
					label="Ilta (18-21)"
				/>
			</RadioGroup>
		</FormControl>
	);
}
