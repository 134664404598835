import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Measurement, Moment, Sample, Tracking } from "../models";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { useTheme } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MomentPicker from "./MomentPicker";
import AdditionalInfoSelect from "./AdditionalInfoSelect";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import EditableSampleTable from "./EditableSampleTable";
import { DataStore } from "@aws-amplify/datastore";
import { calculateAverageSample, toAwsDate } from "../functions";

export default function UpdateMeasurement({
	reFetch,
	measurement,
	currentTracking,
}: {
	reFetch: () => void;
	measurement: Measurement;
	currentTracking: Tracking;
}) {
	const theme = useTheme();
	const fullScreen = useMediaQuery<Boolean>(theme.breakpoints.down("xs"));
	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");

	const [date, setDate] = useState<Date | null>(new Date(measurement.date));
	const [moment, setMoment] = useState<Moment>(Moment[measurement.moment]);
	const [additionalInfo, setAdditionalInfo] = useState<string>(
		measurement.additionalInfo || ""
	);
	const [comment, setComment] = useState<string>(measurement.comment || "");
	const [samples, setSamples] = useState<Sample[]>(measurement.samples);

	const handleCommentChange = (event: React.ChangeEvent<{ value: string }>) =>
		setComment(event.target.value);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setError("");
		setDate(new Date(measurement.date));
		setMoment(Moment[measurement.moment]);
		setComment(measurement.comment || "");
		setSamples(measurement.samples);
		setAdditionalInfo(measurement.additionalInfo || "");
	};
	const updateMeasurement = async () => {
		if (!currentTracking) return;
		if (!date) return;
		const [measurementOfTargetMoment] = await DataStore.query(
			Measurement,
			(m) =>
				m
					.trackingId("eq", currentTracking.id)
					.date("eq", toAwsDate(date))
					.moment("eq", moment)
		);
		if (
			measurementOfTargetMoment &&
			measurementOfTargetMoment.id !== measurement.id
		)
			return setError("Ajankohdalle on jo asetettu mittaustulos!");

		const sampleModels = samples.map(
			({ systolic, diastolic, heartRate }) =>
				new Sample({ systolic, diastolic, heartRate })
		);

		if (!sampleModels.length)
			return setError("Sinun täytyy asettaa vähintään yksi näyte.");

		try {
			setError("");
			setLoading(true);
			await DataStore.save(
				Measurement.copyOf(measurement, (updated) => {
					updated.samples = sampleModels;
					updated.averageSample = calculateAverageSample(sampleModels);
					updated.additionalInfo = additionalInfo;
					updated.comment = comment;
					updated.moment = moment;
					updated.date = toAwsDate(date);
				})
			);
			handleClose();
			reFetch();
		} catch (e) {
			setLoading(false);
			console.error(e);
			setError("Jokin meni pieleen!");
		}
	};

	return (
		<>
			<Button
				style={{ margin: 10 }}
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
			>
				Muokkaa
			</Button>
			<Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
				<DialogTitle>Muokkaa mittaustulosta</DialogTitle>
				<DialogContent>
					<Grid
						container
						direction="column"
						spacing={2}
						justify="space-between"
					>
						<Grid item container justify="space-around">
							<KeyboardDatePicker
								style={{ maxWidth: 200 }}
								margin="normal"
								label="Päivämäärä"
								format="dd/MM/yyyy"
								value={date}
								onChange={setDate}
								inputVariant="outlined"
								minDate={currentTracking && new Date(currentTracking.startDate)}
								maxDate={currentTracking && new Date(currentTracking.endDate)}
							/>
							<MomentPicker moment={moment} setMoment={setMoment} />
						</Grid>
						<Grid item style={{ width: "100%", overflowX: "hidden" }}>
							<EditableSampleTable samples={samples} setSamples={setSamples} />
							{error ? (
								<Alert severity={"error"} style={{ margin: 5 }}>
									{error}
								</Alert>
							) : (
								<Alert severity={"info"} style={{ margin: 5 }}>
									<Typography variant={"caption"}>
										Voit asettaa useamman rinnakkaismittauksen, joista
										tallennetaan keskiarvo.
									</Typography>
								</Alert>
							)}
						</Grid>
						<Grid item container justify="space-around" alignItems="center">
							<AdditionalInfoSelect
								additionalInfo={additionalInfo}
								setAdditionalInfo={setAdditionalInfo}
							/>
							<TextField
								label="Huomiot"
								multiline
								variant="outlined"
								value={comment}
								onChange={handleCommentChange}
								rows={3}
								style={{
									width: "100%",
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Peruuta
					</Button>
					<Button onClick={updateMeasurement} color="primary">
						{loading ? <CircularProgress /> : "Tallenna"}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
