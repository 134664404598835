import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

export default function AdditionalInfoSelect({
	additionalInfo,
	setAdditionalInfo,
}: {
	additionalInfo: string;
	setAdditionalInfo: (additionalInfo: string) => void;
}) {
	const handleAdditionalInfoChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		setAdditionalInfo(event.target.value as string);
	};

	return (
		<FormControl variant="outlined" style={{ minWidth: 120, margin: 5 }}>
			<InputLabel id="select-label">Lisätieto</InputLabel>
			<Select
				labelId="select-label"
				value={additionalInfo}
				onChange={handleAdditionalInfoChange}
				label="Lisätieto"
			>
				<MenuItem value={""}>Ei mitään</MenuItem>
				<MenuItem value={"irregular heartbeat"}>Epäsäännöllinen syke</MenuItem>
				<MenuItem value={"ARR"}>Rytmihäiriö</MenuItem>
				<MenuItem value={"Afib"}>Eteisvärinä</MenuItem>
				<MenuItem value={"PC"}>Lisälyönti</MenuItem>
			</Select>
		</FormControl>
	);
}
