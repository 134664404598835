import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Measurement, Tracking } from "../models";
import MeasurementRow from "./MeasurementRow";
import Typography from "@material-ui/core/Typography";

export default function MeasurementTable({
	reFetch,
	measurements,
	currentTracking,
}: {
	reFetch: () => void;
	measurements: Measurement[];
	currentTracking: Tracking;
}) {
	return (
		<TableContainer style={{ height: "100%", overflowY: "auto", flex: 1 }}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell>
							<Typography variant="subtitle1">Ajankohta</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="subtitle1">Yläpaine (mmHg)</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="subtitle1">Alapaine (mmHg)</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="subtitle1">Syke (bpm)</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="subtitle1">Lisätieto</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{measurements.map((measurement) => (
						<MeasurementRow
							reFetch={reFetch}
							key={measurement.id}
							measurement={measurement}
							currentTracking={currentTracking}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
