import React from "react";
import { Sample } from "../models";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		tableCell: {
			padding: 3,
			paddingTop: 6,
		},
	})
);

export default function EditableSampleTable({
	samples,
	setSamples,
}: {
	samples: Sample[];
	setSamples: (samples: Sample[]) => void;
}) {
	const classes = useStyles();
	const handleChange = (prop: keyof Sample, idx: number) => (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSamples(
			samples.map((sample, index) => {
				if (index === idx) {
					const parsedValue = parseInt(event.target.value, 10);
					return {
						...sample,
						[prop]: isNaN(parsedValue) ? 0 : parsedValue,
					};
				}
				return sample;
			})
		);
	};

	const addSample = () => {
		setSamples([...samples, { systolic: 0, diastolic: 0, heartRate: 0 }]);
	};

	const removeSample = (idx: number) => () => {
		setSamples(samples.filter((sample, i) => i !== idx));
	};

	return (
		<Paper
			style={{
				overflowX: "auto",
			}}
		>
			<TableContainer>
				<Table padding={"none"}>
					<TableHead>
						<TableRow>
							<TableCell className={classes.tableCell}>Yläpaine</TableCell>
							<TableCell className={classes.tableCell}>Alapaine</TableCell>
							<TableCell className={classes.tableCell}>Syke</TableCell>
							<TableCell className={classes.tableCell}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{samples.map((sample, idx) => (
							<TableRow key={idx}>
								<TableCell className={classes.tableCell}>
									<TextField
										label="mmHg"
										type="number"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										value={samples[idx].systolic}
										onChange={handleChange("systolic", idx)}
										inputProps={{
											min: 0,
										}}
										style={{ width: "100%" }}
									/>
								</TableCell>
								<TableCell className={classes.tableCell}>
									<TextField
										label="mmHg"
										type="number"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										value={samples[idx].diastolic}
										onChange={handleChange("diastolic", idx)}
										inputProps={{
											min: 0,
										}}
										style={{ width: "100%" }}
									/>
								</TableCell>
								<TableCell className={classes.tableCell}>
									<TextField
										label="bpm"
										type="number"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										value={samples[idx].heartRate}
										onChange={handleChange("heartRate", idx)}
										inputProps={{
											min: 0,
										}}
										style={{ width: "100%" }}
									/>
								</TableCell>
								<TableCell className={classes.tableCell}>
									<IconButton onClick={removeSample(idx)}>
										<DeleteIcon fontSize={"small"} />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				{samples.length < 3 && (
					<Grid container justify={"center"}>
						<Fab
							color="secondary"
							size="small"
							style={{ margin: 4 }}
							onClick={addSample}
						>
							<AddIcon />
						</Fab>
					</Grid>
				)}
			</TableContainer>
		</Paper>
	);
}
