/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:3a33d14b-4bdc-4ea2-9560-a4710bc338c1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_9377DmykW",
    "aws_user_pools_web_client_id": "39rkv46kpc8v3bmlquil33fbdm",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://zf36qjqkrzh4bjtds5m4uq5zua.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
