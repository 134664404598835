import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FormLabel, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import SampleForm from "./SampleForm";
import { DataStore } from "@aws-amplify/datastore";
import { Measurement, Moment, Sample, Tracking } from "../models";
import { calculateAverageSample, toAwsDate } from "../functions";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Alert } from "@material-ui/lab";
import AdditionalInfoSelect from "./AdditionalInfoSelect";

export default function MeasurementForm({
	reFetch,
	currentTracking,
}: {
	reFetch: () => void;
	currentTracking: Tracking | null;
}) {
	const theme = useTheme();
	const fullScreen = useMediaQuery<Boolean>(theme.breakpoints.down("xs"));
	const [open, setOpen] = useState(false);

	const [sampleRows, setSampleRows] = useState<Array<Sample>>([]);

	const [systolic, setSystolic] = useState<string>("");
	const [diastolic, setDiastolic] = useState<string>("");
	const [heartRate, setHeartRate] = useState<string>("");

	const [error, setError] = useState<string>("");

	const [additionalInfo, setAdditionalInfo] = useState<string>("");

	const [date, setDate] = useState<Date | null>(new Date());

	useEffect(() => {
		const currentDate = new Date();
		setDate(
			currentTracking && currentDate > new Date(currentTracking.endDate)
				? new Date(currentTracking.endDate)
				: currentTracking && currentDate < new Date(currentTracking.startDate)
				? new Date(currentTracking.startDate)
				: currentDate
		);
	}, [currentTracking]);

	const [moment, setMoment] = useState<Moment>(
		new Date().getHours() < 15 ? Moment.MORNING : Moment.EVENING
	);
	const handleMomentChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setMoment(event.target.value as Moment);

	const [comment, setComment] = useState<string>("");

	const [loading, setLoading] = useState<boolean>(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSampleRows([]);
		setSystolic("");
		setDiastolic("");
		setHeartRate("");
		setAdditionalInfo("");
		setComment("");
		const currentDate = new Date();
		setDate(
			currentTracking && currentDate > new Date(currentTracking.endDate)
				? new Date(currentTracking.endDate)
				: currentTracking && currentDate < new Date(currentTracking.endDate)
				? new Date(currentTracking.startDate)
				: currentDate
		);
		setMoment(currentDate.getHours() < 15 ? Moment.MORNING : Moment.EVENING);
		setLoading(false);
		setError("");
	};

	const handleCommentChange = (event: React.ChangeEvent<{ value: string }>) =>
		setComment(event.target.value);

	const createSample = () => {
		if (!systolic || !diastolic || !heartRate) {
			setError("Kaikki kentät ovat vaadittuja!");
			return { error: true };
		}

		const parsedSystolic = parseInt(systolic, 10);
		const parsedDiastolic = parseInt(diastolic, 10);
		const parsedHeartRate = parseInt(heartRate, 10);

		if (
			[parsedSystolic, parsedDiastolic, parsedHeartRate].some(
				(prop) => isNaN(prop) || prop < 0
			)
		) {
			setError("Aseta ainoastaan positiivisia kokonaislukuja!");
			return { error: true };
		}

		const sample = new Sample({
			systolic: parsedSystolic,
			diastolic: parsedDiastolic,
			heartRate: parsedHeartRate,
		});
		return { data: sample };
	};

	const addRow = () => {
		const { data: sample } = createSample();
		if (!sample) return;
		setSampleRows([...sampleRows, sample]);
		setSystolic("");
		setDiastolic("");
		setHeartRate("");
		setError("");
		return [...sampleRows, sample];
	};

	const saveMeasurement = async () => {
		if (!currentTracking) return;
		if (!date) return;
		if (
			(
				await DataStore.query(Measurement, (m) =>
					m
						.trackingId("eq", currentTracking.id)
						.date("eq", toAwsDate(date))
						.moment("eq", moment)
				)
			).length
		)
			return setError(
				"Voit asettaa ajankohdalle ainoastaan yhden mittaustuloksen."
			);
		const sample = (systolic || diastolic || heartRate) && createSample();
		const samples = sampleRows;
		if (sample) {
			const { data, error } = sample;
			if (error) return;
			if (data) samples.push(data);
		}
		if (!samples.length)
			return setError("Sinun täytyy asettaa vähintään yksi näyte.");
		try {
			setError("");
			setLoading(true);
			await DataStore.save(
				new Measurement({
					trackingId: currentTracking.id,
					samples: samples.map(
						({ systolic, diastolic, heartRate }) =>
							new Sample({ systolic, diastolic, heartRate })
					),
					averageSample: calculateAverageSample(samples),
					additionalInfo,
					comment,
					moment,
					date: toAwsDate(date),
				})
			);
			reFetch();
			handleClose();
		} catch (e) {
			setLoading(false);
			console.error(e);
			setError("Jokin meni pieleen!");
		}
	};

	return (
		<div>
			<Button
				style={{ marginTop: 10 }}
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
			>
				Lisää mittaustulos
			</Button>
			<Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
				<DialogTitle>{"Lisää uusi mittaustulos"}</DialogTitle>
				<DialogContent>
					<Grid
						container
						direction="column"
						spacing={2}
						justify="space-between"
					>
						<Grid item container justify="space-around">
							<KeyboardDatePicker
								style={{ maxWidth: 200 }}
								margin="normal"
								label="Päivämäärä"
								format="dd/MM/yyyy"
								value={date}
								onChange={setDate}
								inputVariant="outlined"
								minDate={currentTracking && new Date(currentTracking.startDate)}
								maxDate={currentTracking && new Date(currentTracking.endDate)}
							/>
							<FormControl>
								<FormLabel component="legend">Ajankohta</FormLabel>
								<RadioGroup row value={moment} onChange={handleMomentChange}>
									<FormControlLabel
										value={Moment.MORNING}
										control={<Radio />}
										label="Aamu (06-09)"
									/>
									<FormControlLabel
										value={Moment.EVENING}
										control={<Radio />}
										label="Ilta (18-21)"
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item style={{ width: "100%", overflowX: "hidden" }}>
							<SampleForm
								sampleRows={sampleRows}
								setSystolic={setSystolic}
								setDiastolic={setDiastolic}
								setHeartRate={setHeartRate}
								systolic={systolic}
								diastolic={diastolic}
								heartRate={heartRate}
								addRow={addRow}
							/>
							{error ? (
								<Alert severity={"error"} style={{ margin: 5 }}>
									{error}
								</Alert>
							) : (
								<Alert severity={"info"} style={{ margin: 5 }}>
									<Typography variant={"caption"}>
										Voit asettaa useamman rinnakkaismittauksen, joista
										tallennetaan keskiarvo.
									</Typography>
								</Alert>
							)}
						</Grid>
						<Grid item container justify="space-around" alignItems="center">
							<AdditionalInfoSelect
								additionalInfo={additionalInfo}
								setAdditionalInfo={setAdditionalInfo}
							/>
							<TextField
								label="Huomiot"
								multiline
								variant="outlined"
								value={comment}
								onChange={handleCommentChange}
								rows={3}
								style={{
									width: 250,
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Peruuta
					</Button>
					<Button onClick={saveMeasurement} color="primary">
						{loading ? <CircularProgress /> : "Tallenna"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
