import React from "react";
import { Sample } from "../models";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { Fab } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

export default function SampleForm({
	sampleRows,
	systolic,
	setSystolic,
	diastolic,
	setDiastolic,
	heartRate,
	setHeartRate,
	addRow,
}: {
	sampleRows: Array<Sample>;
	systolic: string;
	setSystolic: (systolic: string) => void;
	diastolic: string;
	setDiastolic: (diastolic: string) => void;
	heartRate: string;
	setHeartRate: (heartRate: string) => void;
	addRow: () => void;
}) {
	const handleChange = (prop: keyof Sample) => (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const setters = {
			systolic: setSystolic,
			diastolic: setDiastolic,
			heartRate: setHeartRate,
		};

		setters[prop](
			isNaN(parseInt(event.target.value, 10)) ? "" : event.target.value
		);
	};

	return (
		<Paper
			style={{
				overflowX: "auto",
			}}
		>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Yläpaine</TableCell>
							<TableCell>Alapaine</TableCell>
							<TableCell>Syke</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sampleRows.map(({ systolic, diastolic, heartRate }, idx) => (
							<TableRow key={idx}>
								<TableCell>{`${systolic} mmHg`}</TableCell>
								<TableCell>{`${diastolic} mmHg`}</TableCell>
								<TableCell>{`${heartRate} bpm`}</TableCell>
							</TableRow>
						))}
						{sampleRows.length < 3 && (
							<TableRow>
								<TableCell>
									<TextField
										label="mmHg"
										type="number"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										value={systolic}
										onChange={handleChange("systolic")}
										inputProps={{
											min: 0,
										}}
									/>
								</TableCell>
								<TableCell>
									<TextField
										label="mmHg"
										type="number"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										value={diastolic}
										onChange={handleChange("diastolic")}
										inputProps={{
											min: 0,
										}}
									/>
								</TableCell>
								<TableCell>
									<TextField
										label="bpm"
										type="number"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										value={heartRate}
										onChange={handleChange("heartRate")}
										inputProps={{
											min: 0,
										}}
									/>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
				{sampleRows.length < 2 && (
					<Grid container justify={"center"}>
						<Fab
							color="secondary"
							size="small"
							style={{ margin: 4 }}
							onClick={addRow}
						>
							<AddIcon />
						</Fab>
					</Grid>
				)}
			</TableContainer>
		</Paper>
	);
}
